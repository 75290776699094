export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [0,4,6];

export const dictionary = {
		"/": [~9],
		"/auth/login": [10,[2]],
		"/auth/login/verification/[slug]": [~11,[2]],
		"/berita": [~12,[3]],
		"/berita/[slug]": [~13,[3]],
		"/pegawai": [~14,[4]],
		"/pegawai/bukti_dukung_skp": [~15,[4]],
		"/pegawai/bukti_dukung_skp/[slug]": [~16,[4]],
		"/pegawai/lhp": [~17,[4]],
		"/pegawai/lhp/upload": [19,[4]],
		"/pegawai/lhp/[slug]": [~18,[4]],
		"/pegawai/monev-pengawasan": [20,[4]],
		"/pegawai/profile": [21,[4]],
		"/pegawai/sakip": [~22,[4]],
		"/pegawai/sakip/recommendation": [~24,[4]],
		"/pegawai/sakip/recommendation/[slug]": [~25,[4]],
		"/pegawai/sakip/[slug]": [~23,[4]],
		"/pegawai/spip/mr": [~26,[4]],
		"/pegawai/spip/mr/pengisian/[slug]": [~27,[4]],
		"/pegawai/st": [28,[4]],
		"/pegawai/st/upload": [30,[4]],
		"/pegawai/st/[slug]": [29,[4]],
		"/pegawai/takah": [31,[4]],
		"/pegawai/tl": [32,[4]],
		"/pegawai/tl/inspektorat": [33,[4]],
		"/pegawai/tl/inspektorat/rekomendasi/[slug]": [35,[4]],
		"/pegawai/tl/inspektorat/rekomendasi/[slug]/edit": [~36,[4]],
		"/pegawai/tl/inspektorat/rekomendasi/[slug]/upload": [37,[4]],
		"/pegawai/tl/inspektorat/[slug]": [~34,[4]],
		"/profile": [38,[5]],
		"/profile/struktur": [39,[5]],
		"/sapa-inspektur": [40,[6]],
		"/sapa-inspektur/sakip": [~41,[6]],
		"/sapa-inspektur/sakip/recommendation": [~42,[6]],
		"/sapa-inspektur/sakip/recommendation/[slug]": [~43,[6]],
		"/sapa-inspektur/spip/mr": [~44,[6]],
		"/sapa-inspektur/spip/mr/pengisian/[slug]": [~45,[6]],
		"/sapa-inspektur/takah": [~46,[6]],
		"/survei/publik": [47,[7]],
		"/wbs": [~48,[8]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';